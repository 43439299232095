<template>
  <v-card :color="modoOscuro ? '#002969' : 'grey lighten-3'" outlined>
    <moduloBannerComponent
      :color="modoOscuro ? modulo.colorOscuro : modulo.colorClasico"
      :titulo="modulo.titulo"
      :icon="modulo.icon"
    />
    <v-container>
      <v-row>
        <v-col sm="6" md="6" lg="6">
          <moduloEjerciciosComponent
            v-for="(modulo, index) in ejerciciosModulos"
            :key="`modulo-${index}`"
            :modulo="modulo"
            :loading="loading"
            style="margin-bottom: 15px"
          />
        </v-col>
        <v-col sm="6" md="6" lg="6">
          <moduloInstructionsComponent
            :loading="loading"
            :modulo="modulo.nombre"
          />
        </v-col>
      </v-row>
    </v-container>
  </v-card>
</template>

<script>
import { mapGetters } from "vuex";

import moduloBannerComponent from "./moduloBanner";
import moduloEjerciciosComponent from "./moduloEjercicios";
import moduloInstructionsComponent from "./moduloInstructions";

export default {
  name: "moduloNiveles",
  props: ["modulo","ejerciciosModulos"],
  components: {
    moduloBannerComponent,
    moduloEjerciciosComponent,
    moduloInstructionsComponent,
  },
  computed: {
    ...mapGetters(["modoOscuro"]),
  },
  data() {
    return {
      loading: false,
      modulos: [],
    };
  }
};
</script>

<style>
.flat-link {
  text-decoration: none;
}
</style>
