<template>
  <v-card :color="modoOscuro ? '#002969' : 'grey lighten-3'" outlined>
    <v-sheet :color="`grey ${modoOscuro ? 'darken-2' : 'lighten-4'}`">
      <v-skeleton-loader
        class="mx-auto"
        max-height="100"
        type="card"
      ></v-skeleton-loader>
    </v-sheet>
    <v-container>
      <v-row>
        <v-col sm="6" md="6" lg="6">
          <div
            v-for="(modulo, index) in 3"
            :key="`modulo-${index}`"
            style="margin-bottom: 15px"
          >
            <div v-for="number in 3" :key="number">
              <v-skeleton-loader
                class="mx-auto"
                type="list-item-avatar-two-line"
                max-width="500"
                tile
                :dark="modoOscuro"
              ></v-skeleton-loader>
            </div>
          </div>
        </v-col>
        <v-col sm="6" md="6" lg="6">
          <div>
            <v-skeleton-loader
              class="mx-auto"
              type="article"
              :dark="modoOscuro"
            ></v-skeleton-loader>
          </div>
        </v-col>
      </v-row>
    </v-container>
  </v-card>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "moduloLoading",
  computed: {
    ...mapGetters(["modoOscuro"]),
  },
  data() {
    return {
      loading: false,
    };
  },
};
</script>

<style>
.flat-link {
  text-decoration: none;
}
</style>
