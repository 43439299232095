import { getServerRequest } from '@/helpers/serverRequest.helper';
import { store } from '@/store/index';
const httpURL = store.getters.httpURL;

export const getModuloByRoute = async(moduloRoute) => {
    const token = store.getters.sessionToken;
    const config = { headers: { token } };
    const url = `${httpURL}/modulos/usuario/${moduloRoute}`;
    return await getServerRequest(url, config);
}

export const getEjerciciosModulo = async(data) => {
    const token = store.getters.sessionToken;
    const config = { headers: { token } };
    const url = `${httpURL}/ejercicios/${data.nombreModulo}`;
    return await getServerRequest(url, config);
}






import { modulosObjectList } from "./modulos.data";
import {
    amplitudVisualInstructions,
    velocidadVisualInstructions,
    retencionInstructions,
    lecturasInstructions
} from "./modulos.data";

export function getModuloByNombre(nombreModulo) {
    return modulosObjectList[nombreModulo];
}

// export function getModuloByRoute(moduloRoute) {
//     const modulo = modulosArray.find(x => x.route == moduloRoute)
//     return modulo;
// }

export function getModuloInstructions(nombreModulo) {
    if (nombreModulo == 'amplitudVisual')
        return amplitudVisualInstructions;
    if (nombreModulo == 'velocidadVisual')
        return velocidadVisualInstructions;
    if (nombreModulo == 'retencion')
        return retencionInstructions;
    if (nombreModulo == 'lecturas')
        return lecturasInstructions;
    return ' ';
}