<template>
  <v-container>
    <v-row dense>
      <v-col cols="12">
        <moduloLoadingComponent v-if="loading"/>
        <moduloNivelesComponent v-else :modulo="modulo" :ejerciciosModulos="ejerciciosModulos" />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapGetters } from "vuex";
import { getModuloByRoute } from "./modulos.service";

import moduloLoadingComponent from "./moduloLoading";
import moduloNivelesComponent from "./moduloNiveles";

export default {
  name: "moduloView",
  components: {
    moduloLoadingComponent,
    moduloNivelesComponent,
  },
  computed: {
    ...mapGetters(["modoOscuro"]),
  },
  data() {
    return {
      loading: false,
      nombreModulo: null,
      modulo: {},
      ejerciciosModulos: []
    };
  },
  created() {
    this.moduloRoute = this.$route.params.moduloRoute;
    this.cargarModulo();
  },
  methods: {
    async cargarModulo() {
      try {
        this.loading = true;
        const serverResponse = await getModuloByRoute(this.moduloRoute);
        this.loading = false;
        this.$validateResponse(serverResponse);
        if (serverResponse.moduloInvalido) this.$router.push("/not-found");
        else{
          this.modulo = serverResponse.modulo;
          this.ejerciciosModulos = serverResponse.ejerciciosModulo;
        }
      } catch (error) {
        this.loading = false;
        this.$appError(error.message);
      }
    },
  },
};
</script>

<style>
.flat-link {
  text-decoration: none;
}
</style>
