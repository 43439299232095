<template>
  <v-card outlined :color="modoOscuro ? 'grey lighten-1' : ''">
    <v-card-title>{{ nivel }}</v-card-title>
    <v-divider></v-divider>
    <v-list :color="modoOscuro ? 'grey lighten-2' : ''">
      
      <v-list-item v-if="loading">
        <v-list-item-action>
          <v-btn icon loading></v-btn>
        </v-list-item-action>
        <v-list-item-content>
          <v-list-item-title>Cargando...</v-list-item-title>
        </v-list-item-content>
      </v-list-item>

      <template v-else v-for="(ejercicio, index) in modulo.ejercicios">
        <v-list-item :key="`ac-item-${index}`">
          <v-list-item-action>
            <v-progress-circular
              size="40"
              :value="ejercicio.rendimiento"
              color="orange"
            >
              <span style="font-size: 14px;"
                ><strong>{{ ejercicio.rendimiento }}</strong></span
              >
            </v-progress-circular>
          </v-list-item-action>

          <v-list-item-content>
            <v-list-item-title>
              {{ ejercicio.nombre }}
            </v-list-item-title>
            <v-list-item-subtitle
              >Repeticiones: {{ ejercicio.repeticiones }}</v-list-item-subtitle
            >
          </v-list-item-content>

          <v-list-item-action>
            <v-btn color="blue darken-2" dark x-small fab elevation="0"
            @click="$router.push(`/ejercicio/${moduloRoute}/${ejercicio.clave}`)"
              ><v-icon>mdi-play</v-icon></v-btn
            >
          </v-list-item-action>
        </v-list-item>

        <v-divider
          v-if="index < modulo.ejercicios.length - 1"
          :key="`ac-divider-${index}`"
          class="mx-3"
        >
        </v-divider>
      </template>
    </v-list>
  </v-card>
</template>

<script>

import { mapGetters } from "vuex";
import {capitalizeFirst} from '@/helpers/text.helper';

export default {
  name: "moduloEjercicios",
  props: ["loading", "modulo"],
  computed: {
    ...mapGetters(["modoOscuro"]),
  },
  data() {
    return {
      nivel: '',
      moduloRoute: ''
    };
  },
  mounted() {
    this.moduloRoute = this.$route.params.moduloRoute;
    this.nivel = capitalizeFirst(this.modulo.nivel)
  },
  methods: {},
};
</script>
