<template>
  <v-card outlined :color="modoOscuro ? 'grey lighten-2' : ''">
    <v-sheet v-if="loading" color="grey lighten-2">
      <v-skeleton-loader
        v-for="n in 5"
        :key="n"
        class="mx-3 pa-2"
        type="paragraph"
      ></v-skeleton-loader>
    </v-sheet>
    <v-card-text v-else class="text-justify" v-html="instrucciones">
    </v-card-text>
  </v-card>
</template>

<script>
import { mapGetters } from "vuex";
import { getModuloInstructions } from "./modulos.service";

export default {
  name: "moduloInstructions",
  props: ["loading","modulo"],
  computed: {
    ...mapGetters(["modoOscuro"]),
  },
  data() {
    return {
      instrucciones: "",
    };
  },
  mounted() {
    this.instrucciones = getModuloInstructions(this.modulo);
  },
  methods: {},
};
</script>
